body {
    overflow-y: scroll;
}

.table_design_area {
    width: 1616px;
    margin: 0px auto 26px;
    display: block;
    background: #FFF;
}

/* .inner_table_height {
    border-radius: 8px;
    border: 1px solid #E0E0E0;
} */

.table_header_area {
    padding: 0 0 13px;
}

.table_header_area .form-control:focus {
    box-shadow: none;
}

.table_header_area .form-control {
    position: relative;
    border-radius: 100px;
    width: 280px;
    height: 36px;
    border: 1px solid #CFD3D4;
    background: #FFF;
    font-size: 13px;
    font-weight: 400;
    padding: 10px 34px;
    color: #0A0A0A;
}

.table_header_area svg.search_icon_svg {
    position: absolute;
    left: 12px;
    top: 10px;
}

.table_header_area .form-control::placeholder {
    font-weight: 400;
    font-size: 13px;
    color: #CFD3D4;
}

.table.table_striped_design {
    vertical-align: middle;
    margin: 0;
}

.custom_data_table tbody tr:nth-child(2n) {
    --bs-table-bg-type: rgba(250, 250, 250, 1);
}

.table_striped_design thead tr th {
    color: #616161;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 7.5px 60px 7.5px 12px;
    white-space: nowrap;
}

.table_striped_design tbody tr td {
    color: #0A0A0A;
    font-size: 13px;
    font-weight: 400;
    padding: 8px 16px;
}

.table_striped_design tbody tr td img {
    width: 24px;
    height: 24px;
    object-fit: contain;
    border-radius: 100px;
    margin-right: 10px;
}

.tableFixHead {
    height: auto;
    max-height: calc(100vh - 243px);
    position: relative;
    overflow: auto;
    border-collapse: separate;
}

.tableFixHead table thead th {
    position: sticky !important;
    top: 0;
    background-color: #f5f5f5;
    z-index: 9;
    cursor: pointer;
}

.tableFixHead table tfoot td {
    bottom: -1px;
}

.tableFixHead table th.fixed-sticky,
.tableFixHead table tfoot td.fixed-sticky {
    z-index: 10;
}

.pagination_design {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 12px;
}

.pagination_design span {
    color: #757575;
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
}

.pagination_design .btn {
    border: none;
    margin: 0;
    padding: 0 0.375rem;
    cursor: pointer;
    opacity: 1;
}

.table_border_radius {
    border: 1px solid #eee;
    border-radius: 8px;
    margin-top: 0px;
}

.custom_data_table {
    vertical-align: middle;
    margin-bottom: 0px;
    border-radius: 8px 8px 0 0;
    border-color: #EEEEEE;
}


.custom_data_table thead tr th.unsort::after {
    background-image: url(../assets/images/unsort.svg);
    background-position: center;
    background-repeat: no-repeat;
    content: '';
    position: absolute;
    top: 12px;
    margin-left: 4px;
    width: 10px;
    height: 10px;
}

.custom_data_table thead tr th.sort-desc::after {
    background-image: url(../assets/images/unsort-one.svg);
    background-position: center;
    background-repeat: no-repeat;
    content: '';
    position: absolute;
    top: 12px;
    margin-left: 4px;
    width: 10px;
    height: 10px;
}

.custom_data_table thead tr th.sort-asc::after {
    background-image: url(../assets/images/unsort-one.svg);
    transform: rotate(180deg);
    background-position: center;
    background-repeat: no-repeat;
    content: '';
    position: absolute;
    top: 12px;
    margin-left: 4px;
    width: 10px;
    height: 10px;
}

.custom_data_table thead tr th.sort-asc:after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.table_two_btn_design .btn {
    height: 36px;
    border-radius: 8px;
    background-color: #FAFAFA;
    border: 1px solid #FAFAFA;
    color: #9E9E9E;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
}

.table_two_btn_design .btn:hover {
    border: 1px solid #E0E0E0;
    background-color: #EEE;
}

.table_two_btn_design .btn.active_btn {
    border: 1px solid rgba(74, 58, 255, 0.40);
    background: rgba(74, 58, 255, 0.06);
    color: #4A3AFF;
}

.table_two_btn_design .btn svg {
    margin-right: 8px;
}

.table_two_btn_design .btn.active_btn svg path {
    fill: #4A3AFF;
}

body::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 100px;
    position: fixed !important;
    right: 0;
    top: 0;
}

body::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 100px;
}

body::-webkit-scrollbar-thumb {
    background: #CFD3D4;
    border-radius: 100px;
}

.btn.common_btn {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #616161;
    padding: 10px 12px;
    border: 1px solid rgba(207, 211, 212, 1);
    display: flex;
    align-items: center;
}

.btn.common_btn.request_approval:active,
.btn.common_btn.request_approval {
    color: #2C6AB7;
    border: 1px solid rgba(44, 106, 183, 0.4);
}

.btn.common_btn svg {
    margin-right: 6px;
}

.table_striped_design tbody tr td img.country_img {
    border-radius: 3px;
    margin-right: 8px;
    height: 17px;
    width: 24px;
}

.overlay_btn_design {
    background-color: rgba(245, 245, 245, 1);
    color: rgba(66, 66, 66, 1);
    border: none;
    font-size: 11px;
    font-weight: 600;
    padding: 0;
    width: 22px;
    height: 18px;
    border-radius: 4px;
}

.overlay_btn_design:hover {
    background-color: rgba(245, 245, 245, 1);
    color: rgba(66, 66, 66, 1);
}

.table_btn_margin_right_6 {
    margin-right: 6px;
}

.page_loader_gif {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    display: grid;
    place-items: center;
}

.page_loader_gif::before {
    position: absolute;
    left: 0;
    top: 0;
    filter: blur(100%);
    width: 100%;
    height: 100%;
    background-color: #0000001a;
    border-radius: 15px;
    content: '';
}

.page_loader_gif img {
    width: 100px;
    position: relative;
}

.no_data_table table {
    width: 100%;
    height: auto;
    min-height: calc(100vh - 245px);
    position: relative;
}

.no_data_table .no_data_content {
    position: absolute;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.no_data_table .no_data_content img {
    padding: 0;
}

.no_data_table .no_data_content h2 {
    font-size: 16px;
    font-weight: 500;
    color: #0A0A0A;
    padding: 17px 0 4px;
}

.no_data_table .no_data_content p {
    font-size: 12px;
    font-weight: 400;
    color: #9E9E9E;
    padding: 0;
}