.header_bg_cls {
    background-image: url(../assets/images/header_one.svg);
    background-position: center;
    background-repeat: no-repeat;
    /* background-size: cover; */
    width: 100%;
    position: fixed;
    z-index: 999;
    top: 0;
}

.new_header_design {
    background-image: url(../assets/images/header_two.svg);
    background-position: center top;
    background-repeat: no-repeat;
    /* background-size: cover; */
    width: 100%;
    height: 180px;
}

.user_bg_colour {
    margin-top: 60px;
}

.header_bg_cls .navbar {
    padding: 10px 26px;
}

.header_bg_cls .navbar-brand img {
    width: 103px;
    height: 27px;
}

.header_bg_cls .navbar-text {
    padding: 0;
}

.user_profile_dropdown .btn:active,
.user_profile_dropdown .btn:hover,
.user_profile_dropdown .btn {
    color: #fff;
    border: none;
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: 400;
    padding: 0;
}

.user_profile_dropdown .dropdown-toggle::after {
    margin-left: 8px;
    border: none;
    background-image: url(../assets/images/arrow-down.svg);
    width: 18px;
    height: 18px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.user_profile_dropdown .dropdown-menu {
    min-width: 200px !important;
}

.user_profile_dropdown .dropdown-menu[data-bs-popper] {
    /* left: -100px !important; */
    top: 35px;
    padding: 0px 12px;
    margin: 0;
    border: none;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
}

.user_profile_dropdown hr {
    margin: 0 0;
    border-color: #e0e0e0;
    opacity: 1;
}

.user_profile_dropdown .dropdown-item:active,
.user_profile_dropdown .dropdown-item {
    padding: 12px 0;
    font-size: 13px;
    background-color: transparent;
    font-weight: 400;
    color: #616161;
    border-radius: 8px;
}

.user_profile_dropdown .dropdown-item:hover {
    color: #4a3aff;
}

.user_profile_dropdown .dropdown-item.user_name_cls {
    color: #212121;
    cursor: default;
}

/* start css for second header */
.header_bg_cls.second_header .navbar {
    padding: 10px 153px;
}

.header_bg_cls.second_header .navbar-nav a,
.header_bg_cls.second_header .navbar-nav .nav-link {
    color: #616161;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    line-height: normal;
    padding: 0 36px 0 0px;
}

.header_bg_cls.second_header .navbar-nav a svg {
    margin-right: 8px;
}

.header_bg_cls.second_header .navbar-nav a.active_li_a,
.header_bg_cls.second_header .navbar-nav .nav-link.active_li_a {
    font-weight: 500;
    background-image: linear-gradient(105deg, #8F00D2 43.5%, #4A3AFF 75.25%);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}

.header_bg_cls.second_header .navbar-nav a.active_li_a svg path {
    stroke: #4A3AFF;
}

.header_bg_cls.second_header .navbar-brand {
    margin: 0;
}

.header_bg_cls.second_header .navbar-brand img {
    margin-right: 65px;
}

.header_bg_cls.second_header .user_profile_dropdown .btn:active,
.header_bg_cls.second_header .user_profile_dropdown .btn:hover,
.header_bg_cls.second_header .user_profile_dropdown .btn {
    color: #424242;
    border: none;
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: 400;
    padding: 0;
}

.header_bg_cls.second_header .user_profile_dropdown .dropdown-toggle::after {
    margin-left: 8px;
    border: none;
    background-image: url(../assets/images/black_down_arrow.svg);
    width: 14px;
    height: 14px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}