.modal_popup_design .modal-content {
    padding: 16px 20px 32px;
}

.modal_popup_design .modal-body {
    padding: 0;
}

.modal_popup_design .modal-header {
    padding: 0 0 12px 0;
}

.modal_popup_design .modal-header .h4 {
    color: #0A0A0A;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
}

.modal_popup_design .modal-header .btn-close:focus {
    box-shadow: none;
}

.modal_popup_design .modal-header .btn-close {
    padding: 0px 10px;
    height: 12px;
}

.copy_msg {
    color: #008500;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
}

.input_addon_cls.copy_link_btn .form-control {
    padding: 0 75px 0 40px;
}

.copy_link_btn .btn {
    width: auto;
    height: auto;
    border-radius: 100px;
    border: 1px solid rgba(74, 58, 255, 0.60);
    background: rgba(74, 58, 255, 0.06);
    color: #4A3AFF;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    position: absolute;
    right: 7px;
    top: 5px;
    padding: 4px 12px;
}