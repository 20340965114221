.addon_checkbox_design .form-check-input[type=checkbox] {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    cursor: pointer;
}

.addon_checkbox_design .form-check-input:checked {
    background-color: rgba(74, 58, 255, 1);
    box-shadow: none;
    border: none;
}

.addon_checkbox_design .form-check-input:focus {
    box-shadow: none;
}


.check_box_area {
    overflow: hidden;
    margin-bottom: 12px;
}

.check_box_area input[type="checkbox"] {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
    transition: 1s;
}

.check_box_design {
    width: auto;
    height: 36px;
    border-radius: 100px;
    border: 1px solid #CFD3D4;
    display: flex;
    align-items: center;
    padding: 0 16px 0 0;
}

.check_box_design.for_effect_cls {
    transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out;
}

.check_box_area .check_box_design::before {
    position: absolute;
    content: '';
    left: 13px;
    top: 10px;
    width: 16px;
    height: 16px;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #E0E0E0;
}

.check_box_area .check_box_design::after {
    position: absolute;
    content: '';
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    left: 16px;
    top: 13px;
    width: 10px;
    height: 10px;
}

.check_box_area input[type="checkbox"]:checked+.check_box_design {
    border: 1px solid #4A3AFF;
    background-color: rgba(74, 58, 255, 0.08);
}

.check_box_area input[type="checkbox"]:checked+.check_box_design::before {
    background-color: #4A3AFF;
    border: none;
}

.check_box_area input[type="checkbox"]:checked+.check_box_design::after {
    background-image: url('../assets/images/gray_clr_icons/check_box_icon.svg');
    /* background-color: #fff; */
}

.check_box_design span {
    color: #0A0A0A;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    padding-left: 36px;
}

.check_box_area input[type="checkbox"]:checked+.check_box_design span {
    font-weight: 500;
}

.inner_line_hr {
    height: 20px;
    width: 1px;
    background-color: #9E9E9E;
    margin: 0 8px;
}

.check_box_design small {
    color: #9E9E9E;
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
}

.blue_text_eye_icon {
    color: #4A3AFF;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    cursor: pointer;
    align-items: center;
}

.input_info_text {
    color: #757575;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 12px;
    display: inline-block;
}

.inner_input_design {
    position: relative;
}

.inner_input_design .form-control {
    width: 70px;
    height: 30px;
    border: none;
    background: transparent;
    padding: 0;
    position: relative;
    z-index: 9;
    color: #0a0a0a;
    border-radius: 0;
    font-size: 13px;
    font-weight: 500;
}

.inner_input_design .form-control:focus,
.inner_input_design .form-control:hover {
    border: none;
    box-shadow: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.ract_drop_accordion .only_for_react_select .css-1fdsijx-ValueContainer {
    padding: 0 12px;
}

.make_custom_tabs_design {
    width: 100%;
    height: auto;
    background-color: #FAFAFA;
    border-bottom: 1px solid #E0E0E0;
    margin-top: 16px;
    padding: 0 12px;
}

.make_custom_tabs_design ul {
    margin: 0;
    padding: 0;
    display: flex;
}

.make_custom_tabs_design ul li {
    list-style-type: none;
}

.make_custom_tabs_design ul li button.btn:active,
.make_custom_tabs_design ul li button.btn:focus {
    border-color: transparent;
}

.make_custom_tabs_design ul li button {
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    padding: 15px 0;
    margin-right: 24px;
}

.make_custom_tabs_design ul li button.active_custom_tab {
    color: #2C6AB7;
    position: relative;
    font-size: 14px;
    line-height: normal;
}

.make_custom_tabs_design ul li button.active_custom_tab::before {
    position: absolute;
    left: 0;
    bottom: -1px;
    content: '';
    width: 100%;
    height: 2px;
    background-color: #2C6AB7;
    border-radius: 100px;
}

.heading_with_line {
    display: flex;
    align-items: center;
}

.user_details_area .step_two_design .heading_with_line h1 {
    color: #0A0A0A;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    padding: 0;
    margin: 0 24px 0 0;
    width: auto;
    white-space: nowrap;
}

.user_details_area .step_two_design .heading_with_line hr {
    border-radius: 6px;
    background-color: #CFD3D4;
    width: 100%;
    height: 1px;
    opacity: 1;
    border: none;
    margin: 0;
}

.user_details_area .step_two_design .no_data_screen {
    padding: 20px;
}

.user_details_area .step_two_design .no_data_screen p {
    font-size: 14px;
    font-weight: 300;
    color: #616161;
    margin: 0;
    padding: 0;
}

.hide_tab_class{
    display:none;
}

