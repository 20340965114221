.bg_color {
    background-color: #fafbfd;
}

/* .bg_color::before {
    position: fixed;
    left: -16%;
    top: -50%;
    content: '';
    background-image: radial-gradient(circle, #4a3aff, #4a3aff7d, #4a3aff3b);
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
    width: 1000px;
    height: 1000px;
    border-radius: 100%;
} */

.user_details_area {
    margin-top: 0px;
    margin-bottom: 100px;
}

.user_details_area h1 {
    color: #FFF;
    font-size: 32px;
    font-weight: 200;
    margin: 0;
    padding-bottom: 24px;
}

.user_profile_loader {
    position: relative;
    overflow: hidden;
    padding: 28px;
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 32px;
}

.user_profile_loader img {
    position: absolute;
    right: -34px;
    top: 50%;
    width: 350px;
    transform: translateY(-50%);
}

.user_profile_loader h2 {
    color: #0A0A0A;
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    padding: 0 0 14px;
}

.user_profile_loader p {
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    padding: 0 0 24px;
    width: 58%;
}

.user_profile_loader.profile_review_design {
    padding: 20px 28px;
    border: 1px solid #FA8E02;
    background-image: linear-gradient(0deg, rgba(255, 159, 41, 0.08) 0%, rgba(255, 159, 41, 0.08) 100%);
}

.user_profile_loader.profile_review_design.profile_not_approved_design {
    border: 1px solid #ED0017;
    background-image: linear-gradient(0deg, rgba(237, 0, 23, 0.06) 0%, rgba(237, 0, 23, 0.06) 100%);
}

.user_profile_loader.profile_review_design h2 {
    color: #FA8E02;
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    padding: 12px 0 8px;
}

.user_profile_loader.profile_review_design.profile_not_approved_design h2 {
    color: #ED0017;
}

.user_profile_loader.profile_review_design p {
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    padding: 0;
    margin: 0;
    width: auto;
}

.gradient_text {
    background-image: linear-gradient(105deg, #8F00D2 43.5%, #4A3AFF 75.25%);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}

.four_step_design {}

.four_step_design p {
    color: #616161;
    font-size: 13px;
    font-weight: 400;
    margin: 0;
}

.four_step_design span {
    color: #9E9E9E;
    font-size: 12px;
    font-weight: 400;
    display: block;
}

.steps_design {
    position: relative;
    padding: 30px;
    background-color: #fff;
    border-radius: 8px;
    margin: 12px 0 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.steps_design:hover {
    box-shadow: 0px 2px 12.6px 0px rgba(95, 95, 95, 0.12);
}

.steps_design img {
    margin-right: 16px;
}

.steps_design h3 {
    color: #0A0A0A;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    padding: 0 0 6px;
    display: flex;
}

.steps_design h3 img {
    margin-left: 6px;
}

.steps_design p {
    color: #9E9E9E;
    font-size: 13px;
    font-weight: 400;
    cursor: pointer;
    margin: 0;
    padding: 0;
}

.steps_design .edit_side_icon p {
    color: #757575;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    padding: 6px 12px;
    background-color: #FAFAFA;
    border-radius: 6px;
}

.steps_design .edit_side_icon p.cursor_pointer_default:hover {
    color: #757575;
    background-color: #FAFAFA;
}

.steps_design .edit_side_icon p:hover {
    background-color: #f5f5f5;
    color: #212121;
}

.steps_design .edit_side_icon p:hover svg path {
    stroke: #212121;
}

.add_btn_design {
    border-radius: 100px;
    border: 1px solid #4A3AFF;
    color: #4A3AFF;
    background-color: transparent;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 8px 35px;
    text-decoration: none;
}

.add_btn_design:hover {
    background-color: rgba(74, 58, 255, 0.12);
}

/* start css for step two */

.user_details_area .step_two_design span.step_count_page {
    color: #8F00D2;
    font-size: 13px;
    font-weight: 500;
    display: block;
    padding: 16px 38px 4px;
}

.user_details_area .step_two_design h4 {
    color: #212121;
    font-size: 18px;
    font-weight: 400;
    display: flex;
    align-items: center;
    margin: 0;
}

.user_details_area .step_two_design h4 svg {
    margin-right: 8px;
    transform: translateY(-1px);
    cursor: pointer;
}

.user_details_area .step_two_design hr {
    opacity: 1;
    border-color: #CFD3D4;
    margin: 18px 0;
}

.user_details_area .step_two_design h1 {
    color: #0A0A0A;
    font-size: 32px;
    font-weight: 200;
    margin: 0;
    width: 90%;
    padding-bottom: 8px;
    line-height: 150%;
}

.user_details_area .step_two_design p {
    color: #616161;
    padding-bottom: 32px;
    font-size: 16px;
    margin: 0;
    font-weight: 300;
    line-height: 150%;
}

.fixed_bottom_buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    z-index: 13;
    justify-content: center;
    padding: 12px;
    box-shadow: 0px -1px 40.8px 0px rgba(62, 62, 62, 0.04);
}

.fixed_bottom_buttons .btn {
    border-radius: 100px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    padding: 12px 60px;
}


.fixed_bottom_buttons .submit_btn:active,
.fixed_bottom_buttons .submit_btn {
    background-color: #4A3AFF;
    border-color: #4A3AFF;
    color: #FFF;
}

.submit_btn_design:hover,
.fixed_bottom_buttons .submit_btn:hover {
    background-color: #1a06ff;
}

.fixed_bottom_buttons .discard_btn {
    border: 1px solid #616161;
    color: #616161;
}

.fixed_bottom_buttons .discard_btn:hover {
    background-color: #e0e0e0;
}

/* step_property_design */
.step_property_design .accordion-item {
    margin-bottom: 16px;
    border-color: transparent;
    background: #FFF;
    border-radius: 8px;
}

.step_property_design .accordion-item .accordion-button.collapsed {
    border: 1px solid #E0E0E0;
    box-shadow: none;
}

.step_property_design .accordion-item .accordion-button.collapsed:hover {
    border: 1px solid #4a3aff;
}

.step_property_design .accordion-item .accordion-button {
    box-shadow: none;
    border-bottom: none;
    padding: 20px;
    z-index: 0;
    position: relative;
    border: 1px solid #8F00D2;
    border-radius: 8px 8px 0 0;
}

.step_property_design .accordion-item .accordion_body_hide .accordion-button {
    border-radius: 8px;
}

/* .step_property_design .accordion-item .accordion-collapse.collapse {
    border: 1px solid transparent;
} */

.step_property_design .accordion-item .custom_body_design,
.step_property_design .accordion-item .accordion-collapse .accordion-body {
    border: 1px solid #8F00D2;
    border-top: 1px solid transparent;
    padding: 20px 24px;
    border-radius: 0 0 8px 8px;
}


.step_property_design .accordion-item .showing_checkbox .accordion-button::before {
    position: absolute;
    content: '';
    width: 20px;
    height: 20px;
    background-image: url(../assets/images/blue_tick.svg);
    right: -6px;
    top: -6px;
    border-radius: 100px;
}

.step_property_design .accordion-item .accordion-button.collapsed::before {
    display: none;
}

.step_property_design .accordion-item .accordion-button::after {
    display: none;
}

.step_property_design .accordion-item .accordion-button.collapsed {
    border-radius: 8px;
}

.step_property_design .accordion-button:focus {
    box-shadow: none;
}

.step_property_design .accordion-button:not(.collapsed) {
    background-color: transparent;
}

.inner_accord_heading strong {
    display: block;
    color: #0a0a0a;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 4px;
}

.inner_accord_heading span {
    color: #9E9E9E;
    font-size: 13px;
    font-weight: 400;
}

.step_property_design .accordion-button.collapsed .inner_accord_heading img.hide_show_icons_clr {
    display: none;
}

.step_property_design .accordion-button .inner_accord_heading img.hide_show_icons_clr {
    display: block;
}

.step_property_design .accordion-button.collapsed .inner_accord_heading img.hide_show_icons_gray {
    display: block;
}

.step_property_design .accordion-button .inner_accord_heading img.hide_show_icons_gray {
    display: none;
}

.custom_body_design {
    padding: 16px 20px;
    border-top: 1px solid #E0E0E0;
}

.radio_box_area {
    overflow: hidden;
}

.radio_box_area input[type="radio"] {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
}

.radio_box_design {
    width: 100%;
    height: 36px;
    border-radius: 100px;
    border: 1px solid #CFD3D4;
    display: flex;
    align-items: center;
    padding: 0 16px 0 0;
}

.radio_box_area .radio_box_design::before {
    position: absolute;
    content: '';
    left: 13px;
    top: 11px;
    width: 14px;
    height: 14px;
    border-radius: 100px;
    background-color: #fff;
    border: 1px solid #CFD3D4;
}

.radio_box_area .radio_box_design::after {
    position: absolute;
    content: '';
    left: 17px;
    top: 15px;
    width: 6px;
    height: 6px;
    border-radius: 100px;
    background-color: #fff;
}

.radio_box_area input[type="radio"]:checked+.radio_box_design {
    border: 1px solid #4A3AFF;
    background-color: rgba(74, 58, 255, 0.08);
}

.radio_box_area input[type="radio"]:checked+.radio_box_design::before {
    background-color: #4A3AFF;
    border: none;
}

.radio_box_area input[type="radio"]:checked+.radio_box_design::after {
    background-color: #fff;
}

.radio_box_design span {
    color: #0A0A0A;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    padding-left: 34px;
}

.radio_box_area input[type="radio"]:checked+.radio_box_design span {
    font-weight: 500;
}

.submit_btn_design.onboarding_btn_show {
    width: 243px;
}

.loader_img {
    position: relative;
    height: calc(100vh - 72px);
    width: 100%;
}

.loader_img img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 5%;
}

.steps_design .edit_side_icon .p_btn {
    padding: 4px 12px;
    align-items: center;
    border-radius: 6px;
    background-color: rgba(32, 191, 85, 0.08);
    color: #008500;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    letter-spacing: -0.12px;
}

.steps_design .edit_side_icon .p_btn:hover {
    background-color: rgba(32, 191, 85, 0.08);
    color: #008500;
}

.only_for_react_select .css-hlgwow {
    padding: 0;
}

.only_for_react_select .css-t3ipsp-control .css-1fdsijx-ValueContainer,
.only_for_react_select .css-13cymwt-control .css-1fdsijx-ValueContainer {
    padding: 0 0 0 38px;
}

.only_for_react_select .css-19bb58m {
    padding: 0 0 0 38px;
}

.only_for_react_select .css-1cfo1cf {
    padding: 0;
}

.merge_input_design.only_for_react_select .css-1fdsijx-ValueContainer .css-1dimb5e-singleValue {
    padding: 0;
}

.merge_input_design.only_for_react_select .css-1n6sfyn-MenuList,
.merge_input_design.only_for_react_select .css-qr46ko {
    max-height: 160px;
}


.input_addon_cls .input_type_file_design small {
    color: #CFD3D4;
    font-weight: 400;
    font-size: 13px;
}

.after_upload_filename {
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #0A0A0A !important;
    white-space: nowrap !important;
}

.merge_input_design.only_for_react_select .css-hlgwow,
.merge_input_design.only_for_react_select .css-166bipr-Input,
.merge_input_design.only_for_react_select .css-1jqq78o-placeholder {
    font-size: 13px;
    font-weight: 500;
    color: #0A0A0A;
    padding: 0 0 0 0px;
}

.merge_input_design.only_for_react_select .css-1jqq78o-placeholder {
    color: #CFD3D4 !important;
    font-weight: 400;
}

.only_for_react_select .css-hlgwow .css-1dimb5e-singleValue,
.only_for_react_select .css-hlgwow .css-1cfo1cf,
.only_for_react_select .css-hlgwow .css-1jqq78o-placeholder {
    padding: 0 0 0 38px !important;
}


/* this css for react select within accordion design */

.accordion_react_select_design .css-1fdsijx-ValueContainer {
    padding: 0 0 0 12px;
}

.accordion_react_select_design .css-166bipr-Input,
.accordion_react_select_design .css-1jqq78o-placeholder {
    font-size: 13px;
    font-weight: 500;
    color: #0A0A0A;
    padding: 0;
}

.accordion_react_select_design .css-hlgwow {
    padding: 0 12px;
}

.accordion_react_select_design .css-1jqq78o-placeholder {
    color: #CFD3D4 !important;
    font-weight: 400;
}

.accordion_react_select_design .css-1dimb5e-singleValue {
    font-size: 13px;
    font-weight: 500;
    position: relative;
    color: #0A0A0A;
    text-align: left;
    overflow: visible;
}

.accordion_react_select_design .css-1n6sfyn-MenuList,
.accordion_react_select_design .css-qr46ko {
    max-height: 200px;
}

.accordion_react_select_design .css-1nmdiq5-menu .css-tr4s17-option {
    font-size: 13px;
    font-weight: 500;
    /* text-align: center; */
    color: #4A3AFF;
    background-color: #4a3aff0f;
}


.accordion_react_select_design .css-1nmdiq5-menu .css-qr46ko .css-d7l1ni-option,
.accordion_react_select_design .css-1nmdiq5-menu .css-1n6sfyn-MenuList .css-d7l1ni-option {
    background-color: #b7b7b721;
}

.accordion_react_select_design .css-1nmdiq5-menu .css-10wo9uf-option,
.accordion_react_select_design .css-1nmdiq5-menu .css-d7l1ni-option,
.accordion_react_select_design .css-1nmdiq5-menu .css-10wo9uf-option {
    font-size: 13px;
    font-weight: 500;
    color: #0A0A0A;
    /* text-align: center; */
}

.accordion_react_select_design .css-t3ipsp-control:focus,
.accordion_react_select_design .css-t3ipsp-control:hover,
.accordion_react_select_design .css-t3ipsp-control {
    border-color: #6366F1 !important;
    box-shadow: none !important;
}

.css-1xc3v61-indicatorContainer {
    padding: 7px !important;
}

.btn.remove_red_btn {
    color: #F83A39;
    padding: 0;
    font-size: 13px;
    display: flex;
    align-items: center;
}

.btn.remove_red_btn:active {
    color: #F83A39;
    border-color: transparent;
}

.btn.remove_red_btn:hover svg path {
    stroke: #DC0011;
}

.btn.remove_red_btn:hover {
    color: #DC0011;
}

.btn.remove_red_btn svg {
    margin: 0 4px 0 16px;
}

/* Button style */

button.ripple {
    position: relative;
    display: inline-block;
    background-color: #4A3AFF;
    color: #fff;
    text-decoration: none;
    vertical-align: middle;
    overflow: hidden;
    cursor: pointer;
    outline: 0;
    z-index: 1;
    transition: all 0.1s ease-in;
    border-radius: 100px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    border: 1px solid #4A3AFF;
}


button.ripple:hover,
button.ripple.button:focus {
    opacity: 0.9;
}

button.ripple:hover {
    background-color: #1a06ff;
    color: #fff;
}


@-webkit-keyframes ripple {
    0% {
        width: 0;
        height: 0;
        opacity: 0.5;
    }

    100% {
        width: 150px;
        height: 150px;
        opacity: 0;
    }
}

@keyframes ripple {
    0% {
        width: 0;
        height: 0;
        opacity: 0.5;
    }

    100% {
        width: 150px;
        height: 150px;
        opacity: 0;
    }
}

.ripple:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: currentColor;
    visibility: hidden;
    z-index: 2;
}

.ripple:not(:active):before {
    -webkit-animation: ripple 0.4s cubic-bezier(0, 0, 0.2, 1);
    animation: ripple 0.4s cubic-bezier(0, 0, 0.2, 1);
    transition: visibility 0.4s step-end;
}

.ripple:active:before {
    visibility: visible;
}