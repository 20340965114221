.mb-24 {
    margin-bottom: 24px;
}

.mb-20 {
    margin-bottom: 20px;
    line-height: 14px;
}

.pb-24 {
    padding-bottom: 24px !important;
}

.mb-36 {
    margin-bottom: 36px;
    line-height: 14px;
}

.mt_36 {
    margin-top: 36px;
}

.mt-28 {
    margin-top: 28px;
}

.mt-20 {
    margin-top: 20px;
    line-height: 14px;
}

.mt-32 {
    margin-top: 32px;
}

.mt-28 {
    margin-top: 28px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-8 {
    margin-top: 8px;
}

.mt-24 {
    margin-top: 24px;
}

.mt-52 {
    margin-top: 52px;
}

.mt-12 {
    margin-top: 12px;
}

.mt_8 {
    margin-top: 8px;
}

.me_8 {
    margin-right: 8px;
}

.ms_8 {
    margin-left: 8px;
}

.me_4 {
    margin-right: 4px;
}

.mt_2 {
    margin-top: 2px;
}

.me_10 {
    margin-right: 10px;
}

.me_16 {
    margin-right: 16px;
}

.me_16 {
    margin-right: 16px;
}

.me_6 {
    margin-right: 6px;
}

.me_2 {
    margin-right: 2px;
}

.me_12 {
    margin-right: 12px;
}

.me_24 {
    margin-right: 24px;
}

.me_20 {
    margin-right: 20px;
}

.ms_4 {
    margin-left: 4px;
}

.mx_8 {
    margin: 0 8px;
}

.p_12 {
    padding: 12px !important;
}

.p_12 {
    padding: 12px !important;
}

.p_x_12_y_10 {
    padding: 10px 12px !important;
}

.pe_15 {
    padding-right: 15px !important;
}

.pe_4 {
    padding-right: 4px !important;
}

.pb_32 {
    padding-bottom: 32px !important;
}

.transform_y_2 {
    transform: translateY(2px);
}

.transform_y_-2 {
    transform: translateY(-2px);
}

.transform_y_-1 {
    transform: translateY(-1px);
}

.height_300 {
    height: 300px;
}

.height_250 {
    height: 250px;
}

.height_203 {
    height: 203px !important;
}

.height_200 {
    height: 200px;
}

.height_150 {
    height: 150px;
}

.height_100 {
    height: 100px !important;
}

.height_72 {
    height: 72px !important;
}

.border_radius_16 {
    border-radius: 16px !important;
}

.w_452 {
    width: 452px;
}

.w_130 {
    width: 130px !important;
}

.w_185 {
    width: 185px !important;
}

.w_202 {
    width: 202px !important;
}

.w_210 {
    width: 210px;
}

.w_75 {
    width: 75px !important;
}

.w_780 {
    width: 780px !important;
}

.w_available {
    width: -webkit-fill-available !important;
    /* width: fill-available !important; */
    width: stretch !important;
    width: -moz-available !important;
    height: auto !important;
}

.container_width {
    width: 952px;
    margin: 0 auto;
    display: block;
}

.border_radius_18 {
    border-radius: 18px !important;
}

.cursor_pointer {
    cursor: pointer;
}

.cursor_not_allowed {
    cursor: not-allowed !important;
}

.cursor_pointer_default {
    cursor: default !important;
}

.fn_size_13 {
    font-size: 13px !important;
}

.dot_css {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    padding: 0 0 0 12px;
}

.dot_css.active_green,
.dot_css.active_yellow {
    position: relative;
}

.dot_css.active_green::before,
.dot_css.active_yellow::before {
    position: absolute;
    left: 0;
    top: 57%;
    transform: translateY(-50%);
    width: 5px;
    height: 5px;
    content: '';
    border-radius: 100px;
}

.dot_css.active_yellow::before {
    background-color: #FFB200;
}

.dot_css.active_yellow {
    color: #FFB200;
}

.dot_css.active_green {
    color: #008500;
}

.dot_css.active_green::before {
    background-color: #008500;
}

.text_area_css .form-control:focus {
    box-shadow: none;
}

.show_mobile_view {
    display: none;
    position: fixed;
    left: 0;
    text-align: center;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    z-index: 999;
}

.show_mobile_view img.one_logo {
    width: 139px;
    margin: 68px auto 150px;
    display: block;
}

.show_mobile_view img.two_logo {
    margin-bottom: 32px;
}

.show_mobile_view h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 24.2px;
    color: #0A0A0A;
    margin: 0;
    padding: 0 36px 12px;
}

.show_mobile_view p {
    font-weight: 400;
    font-size: 16px;
    line-height: normal;
    color: #757575;
    padding: 0 66px 60px;
    margin: 0;
}