@media only screen and (max-width: 1540px) {
    .sign_bg_design {
        height: auto;
        padding: 20px 0;
    }

    .sign_up_page {
        height: 630px;
        min-height: 630px;
    }

    .mt-32 {
        margin-top: 22px;
    }

    .table_design_area {
        width: 1226px;
    }

    .input_addon_cls .file_close_btn_design.file_close_btn_design_inner {
        right: 101px;
    }

    .tabs_design_area .tab-content {
        margin: 0 50px;
        width: 100%;
    }

}

@media only screen and (max-width: 920px) {

    .logo_area img {
        margin-top: 28px;
        width: 139px;
        margin-bottom: 28px;
    }

    .sign_up_page {
        width: 100%;
        flex-direction: column;
        height: auto;
        min-height: auto;
    }

    .input_side_area {
        width: 56%;
        border-radius: 20px;
        padding: 28px 16px;
        height: auto;
        margin: 15px auto 20px;
    }

    .sign_bg_design {
        padding: 0;
        display: block;
        height: auto;
        min-height: 100vh;
    }

    .graph_side_area {
        background-image: none;
        padding: 0;
        width: 100%;
        height: auto;
    }

    .graph_side_area h1 {
        text-align: center;
        font-size: 20px;
        padding-bottom: 6px;
    }

    .graph_side_area p {
        text-align: center;
        font-size: 12px;
        font-weight: 300;
        opacity: 0.8;
    }

    .img_set_bottom {
        display: none;
    }

    .sign_up_page.mt-32 {
        margin: 0;
    }

    .mobile_cls_design {
        height: auto;
        padding: 0 20px 20px;
        position: relative;
        min-height: 100vh;
        box-sizing: content-box;
    }

    .merge_input_design .css-13cymwt-control,
    .merge_input_design .css-t3ipsp-control {
        width: 130px;
    }

    .w_202.mobile_view_im {
        width: 100% !important;
    }

    .forgot_link {
        padding-top: 0px;
    }

    .input_side_area .diff_text_content h1 {
        font-size: 24px;
        padding-bottom: 4px;
    }

    .input_side_area .diff_text_content p {
        width: 80%;
    }

    .Toastify__toast-container {
        width: 350px !important;
    }

    .Toastify__toast-container--top-left,
    .Toastify__toast-container--top-center,
    .Toastify__toast-container--top-right {
        top: 10px !important;
        transform: translateX(-50%) !important;
        left: 50% !important;
    }

    .Toastify__close-button.Toastify__close-button--light {
        display: none !important;
    }

    .show_mobile_view {
        display: block;
    }

    .custom_check_design .form-check-input {
        width: 18px;
        height: 18px;
        margin-right: 8px;
    }

}

@media only screen and (max-width: 768px) {

    .logo_area img {
        margin-top: 28px;
        width: 139px;
        margin-bottom: 28px;
    }

    .sign_up_page {
        width: 100%;
        flex-direction: column;
        height: auto;
        min-height: auto;
    }

    .input_side_area {
        width: 56%;
        border-radius: 20px;
        padding: 28px 16px;
        height: auto;
        margin: 15px auto 20px;
    }

    .sign_bg_design {
        padding: 0;
        display: block;
        height: auto;
        min-height: 100vh;
    }

    .graph_side_area {
        background-image: none;
        padding: 0;
        width: 100%;
        height: auto;
    }

    .graph_side_area h1 {
        text-align: center;
        font-size: 20px;
        padding-bottom: 6px;
    }

    .graph_side_area p {
        text-align: center;
        font-size: 12px;
        font-weight: 300;
        opacity: 0.8;
    }

    .img_set_bottom {
        display: none;
    }

    .sign_up_page.mt-32 {
        margin: 0;
    }

    .mobile_cls_design {
        height: auto;
        padding: 0 20px 20px;
        position: relative;
        min-height: 100vh;
        box-sizing: content-box;
    }

    .merge_input_design .css-13cymwt-control,
    .merge_input_design .css-t3ipsp-control {
        width: 130px;
    }

    .w_202.mobile_view_im {
        width: 100% !important;
    }

    .forgot_link {
        padding-top: 0px;
    }

    .input_side_area .diff_text_content h1 {
        font-size: 24px;
        padding-bottom: 4px;
    }

    .input_side_area .diff_text_content p {
        width: 80%;
    }

    .Toastify__toast-container {
        width: 350px !important;
    }

    .Toastify__toast-container--top-left,
    .Toastify__toast-container--top-center,
    .Toastify__toast-container--top-right {
        top: 10px !important;
        transform: translateX(-50%) !important;
        left: 50% !important;
    }

    .Toastify__close-button.Toastify__close-button--light {
        display: none !important;
    }

    .show_mobile_view {
        display: block;
    }

}

@media only screen and (max-width: 600px) {

    .logo_area img {
        margin-top: 28px;
        width: 139px;
        margin-bottom: 28px;
    }

    .sign_up_page {
        width: 100%;
        flex-direction: column;
        height: auto;
        min-height: auto;
    }

    .input_side_area {
        width: 100%;
        border-radius: 20px;
        margin-top: 15px;
        padding: 28px 16px;
        height: auto;
        margin-bottom: 20px;
    }

    .sign_bg_design {
        padding: 0;
        display: block;
        height: auto;
        min-height: 100vh;
    }

    .graph_side_area {
        background-image: none;
        padding: 0;
        width: 100%;
        height: auto;
    }

    .graph_side_area h1 {
        text-align: center;
        font-size: 20px;
        padding-bottom: 6px;
    }

    .graph_side_area p {
        text-align: center;
        font-size: 12px;
        font-weight: 300;
        opacity: 0.8;
    }

    .img_set_bottom {
        display: none;
    }

    .sign_up_page.mt-32 {
        margin: 0;
    }

    .mobile_cls_design {
        height: auto;
        padding: 0 20px 20px;
        position: relative;
        min-height: 100vh;
        box-sizing: content-box;
    }

    .merge_input_design .css-13cymwt-control,
    .merge_input_design .css-t3ipsp-control {
        width: 130px;
    }

    .w_202.mobile_view_im {
        width: 100% !important;
    }

    .forgot_link {
        padding-top: 0px;
    }

    .input_side_area .diff_text_content h1 {
        font-size: 24px;
        padding-bottom: 4px;
    }

    .input_side_area .diff_text_content p {
        width: 80%;
    }

    .Toastify__toast-container {
        width: 350px !important;
    }

    .Toastify__toast-container--top-left,
    .Toastify__toast-container--top-center,
    .Toastify__toast-container--top-right {
        top: 10px !important;
        transform: translateX(-50%) !important;
        left: 50% !important;
    }

    .Toastify__close-button.Toastify__close-button--light {
        display: none !important;
    }

    .show_mobile_view {
        display: block;
    }

}