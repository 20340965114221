.page_heading_text {
    margin: 80px 0 28px;
}

.page_heading_text h1 {
    color: #0A0A0A;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}

.tabs_design_area {
    display: flex;
    width: 100%;
    align-items: flex-start;
}

.tabs_design_area .tab-content {
    margin: 0 206px;
    width: 100%;
}

.tabs_design_area .nav-item .nav-link {
    white-space: nowrap;
    text-align: left;
    margin-bottom: 16px;
    color: #757575;
    font-size: 16px;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.16px;
    padding: 12px;
    border-radius: 4px;
}

.tabs_design_area .nav-item .nav-link:hover {
    background-color: #FAFAFA;
    font-size: 16px;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.16px;
    padding: 12px;
}

.tabs_design_area .nav-item .nav-link svg {
    margin-right: 12px;
}

.tabs_design_area .nav-item .nav-link.active {
    color: var(--Theme-1, #4A3AFF);
    font-weight: 400;
    background-color: transparent;
}

.tabs_design_area .nav-item .nav-link.active svg path {
    fill: #4A3AFF;
}

/* .tabs_design_area .nav-item .nav-link.active svg.stroke_clr path {
    stroke: #4A3AFF;
} */

.update_user_btn {
    width: 250px;
    height: 40px;
    border-radius: 100px;
    color: #FFF;
    background-color: #4A3AFF;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
}

.update_user_btn:hover {
    color: #FFF;
    background-color: #1a06ff;
}

.network_profile_accordion .accordion-item {
    margin-bottom: 16px;
    border-color: transparent;
    background: #FFF;
    border-radius: 8px;
}

.network_profile_accordion .accordion-button:not(.collapsed) {
    background-color: transparent;
}

.network_profile_accordion .accordion-item .accordion-button {
    border: 1px solid #E0E0E0;
    box-shadow: none;
    border-radius: 8px;
}

.network_profile_accordion .accordion-item .accordion-button:hover {
    border: 1px solid #4a3aff;

}

.network_profile_accordion .accordion-item .accordion-button.collapsed {
    border: 1px solid #8F00D2;
    box-shadow: none;
    border-radius: 8px 8px 0 0;
}

.network_profile_accordion .accordion-button::after {
    display: none;
}

.network_profile_accordion .accordion-item .custom_body_design,
.network_profile_accordion .accordion-item .accordion-collapse .accordion-body {
    border: 1px solid #8F00D2;
    border-top: 1px solid transparent;
    padding: 20px 24px;
    border-radius: 0 0 8px 8px;
}

.network_profile_accordion .accordion-item {
    position: relative;
}

/* .network_profile_accordion .hide_clickable {
    position: relative;
    overflow: hidden;
}

.network_profile_accordion .hide_clickable::after {
    content: '';
    position: absolute;
    left: 0;
    border-radius: 0px;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background-color: red;
} */

.network_profile_accordion .overlap_switch_btn {
    position: absolute;
    z-index: 9;    
    right: 20px;
    top: 20px;
}

.network_profile_accordion .css-dev-only-do-not-override-zl9ks2 .ant-switch-inner {
    background-color: #E0E0E0;
}

.network_profile_accordion .ant-switch-checked .ant-switch-inner {
    background-color: #4A3AFF;
}