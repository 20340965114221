@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Nunito:wght@200;300;400;500;600;700;800;900;1000&display=swap');

* {
    font-family: 'Inter', sans-serif;
}

button,
input,
optgroup,
select,
textarea {
    font-family: 'Inter', sans-serif;
}


.dark_blue_text_clr {
    color: #2C6AB7 !important;
}

.blue_text_clr {
    color: #4A3AFF !important;
}

.font_weight_600 {
    font-weight: 600 !important;
}

.sign_bg_design {
    width: 100%;
    height: 100vh;
    background-image: url(../assets/images/Deep\ Waves.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sign_up_page {
    width: 888px;
    height: 642px;
    min-height: 685px;
    background-color: transparent;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    display: flex;
}

.graph_side_area {
    background-image: linear-gradient(181deg, rgba(255, 255, 255, 0.30) 1.15%, rgb(255 255 255 / 22%) 98.91%);
    width: 50%;
    height: 100%;
    padding: 40px;
    position: relative;
}

.graph_side_area h1 {
    color: #FFF;
    font-size: 28px;
    font-weight: 500;
    text-align: left;
    margin: 0;
    padding-bottom: 8px;
}

.graph_side_area p {
    color: #FFF;
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    margin: 0;
}

.img_set_bottom {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
}

.input_side_area {
    background-color: #fff;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
}

.input_addon_cls {
    position: relative;
    margin-bottom: 12px;
}

.input_addon_cls span {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-55%);
}

.input_addon_cls .file_close_btn_design {
    right: 101px;
    cursor: pointer;
    background: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 5px;
}

.input_addon_cls .file_close_btn_design.file_close_btn_design_inner {
    right: 104px;
}

.input_addon_cls .file_close_btn_design:hover svg path {
    stroke: #616161;
}

.merge_input_design {
    border: 1px solid #CFD3D4;
    position: relative;
    border-radius: 100px;
}

.input_side_area .form-control {
    border-radius: 100px;
    border: 1px solid #CFD3D4;
    font-size: 13px;
    /* background-color: #FFF; */
    height: 36px;
    font-weight: 500;
    padding: 0 40px;
    position: relative;
    color: #0A0A0A;
}

textarea.form-control {
    resize: none;
}

/* width */
textarea.form-control::-webkit-scrollbar {
    width: 0px;
}

/* Track */
textarea.form-control::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
textarea.form-control::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
textarea.form-control::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.input_side_area .form-control:disabled {
    border: 1px solid #CFD3D4;
    background-color: #F5F5F5;
    cursor: not-allowed;
    color: #424242;
}

.input_side_area .form-control:disabled:hover {
    box-shadow: none;
}

.input_side_area .form-control:hover {
    /* color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    outline: 0; */
    border: 1px solid#CFD3D4;
    box-shadow: 0 0 0 0.25rem #EEE;
}

.input_side_area .form-control:focus {
    border: 1px solid #2C6AB7;
    box-shadow: none;
}

.input_side_area .form-control::placeholder {
    color: #CFD3D4;
    font-weight: 400;
}

.input_side_area .fill_value span svg path {
    fill: #2C6AB7;
}

.alert_msg {
    color: #F00;
    font-size: 12px;
    font-weight: 400;
}

.input_side_area .wrong_value span svg path {
    fill: #FC1B13;
}

.input_side_area label {
    color: #424242;
    font-size: 12px;
    margin: 0;
    padding-bottom: 8px;
    font-weight: 500;
    text-align: left;
    display: block;
}

.forgot_link {
    color: #616161;
    font-size: 12px;
    font-weight: 400;
    text-decoration: none;
    padding-top: 6px;
}

.forgot_link:hover {
    color: #0A0A0A;
    text-decoration: underline;
}

.submit_btn_design:hover,
.submit_btn_design {
    width: 100%;
    height: 40px;
    border-radius: 100px;
    background-color: #4A3AFF;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
}

.input_side_area p {
    color: #616161;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
}

.input_side_area p a {
    color: #0A0A0A;
}

.pass_word_checker {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-56%);
}

.pass_word_checker.percentage_icon {
    right: -4px;
    background: #f1f0ff;
    padding: 0 2px;
    z-index: 9;
    transform: translateY(-8px);
}

.back_btn {
    position: absolute;
    left: 40px;
    top: 40px;
    cursor: pointer;
}

.input_side_area .diff_text_content h1 {
    color: #0A0A0A;
    font-size: 28px;
    text-align: left;
    font-weight: 400;
    margin: 0;
    padding-bottom: 12px;
}

.input_side_area .diff_text_content p {
    color: #616161;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    text-align: left;
    letter-spacing: -0.12px;
}

.input_side_area .diff_text_content p a {
    color: #4A3AFF;
    font-weight: 500;
    text-decoration: none;
}

.input_side_area .diff_text_content .gif_bg {
    border-radius: 12px;
    background-color: rgba(74, 58, 255, 0.10);
    padding: 15px;
    width: 72px;
    height: 72px;
    margin: 52px 0 20px;
}


/* new */

.custom_check_design .form-check-input {
    width: 16px;
    height: 16px;
    margin-right: 6px;
}

.custom_check_design .form-check-input:checked {
    background-color: #4A3AFF;
    border-color: #4A3AFF;
}

.custom_check_design .form-check-input:focus {
    box-shadow: none;
}

.custom_check_design label {
    color: #424242;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.13px;
}

.custom_check_design label a {
    color: #6366F1;
    font-size: 13px;
    font-weight: 500;
    text-decoration: none;
}

.merge_input_design .form-control {
    border-color: transparent;
    border-radius: 0 20px 20px 0;
    width: auto;
    border-left: 1px solid #CFD3D4;
    padding: 0 3px 0 12px;
}

.merge_input_design .css-t3ipsp-control,
.merge_input_design .css-13cymwt-control {
    width: 160px;
    min-height: 36px;
    cursor: pointer;
    border: none;
    border-radius: 20px 0 0 20px;
}

.merge_input_design.only_for_border_cls .css-t3ipsp-control,
.merge_input_design.only_for_border_cls .css-13cymwt-control {
    height: 36px !important;
    min-height: 36px;
}

.merge_input_design .css-t3ipsp-control:focus,
.merge_input_design .css-t3ipsp-control:hover,
.merge_input_design .css-t3ipsp-control {
    border-color: #6366F1 !important;
    box-shadow: none !important;
}

.merge_input_design .css-olqui2-singleValue,
.merge_input_design .css-1dimb5e-singleValue {
    font-size: 13px;
    font-weight: 500;
    position: relative;
    color: #0A0A0A;
    padding: 0 0 0 29px;
    text-align: left;
    overflow: visible;
}

.merge_input_design .css-16xfy0z-control .css-hlgwow .css-olqui2-singleValue {
    color: #424242 !important;
}

.merge_input_design .css-hlgwow .css-olqui2-singleValue {
    padding: 0 0 0 38px;
}

.merge_input_design .css-1jqq78o-placeholder {
    padding: 0 0 0 29px;
}

.merge_input_design .css-tj5bde-Svg {
    fill: #757575;
}

.merge_input_design .css-1nmdiq5-menu {
    overflow: hidden;
    border-radius: 8px;
    z-index: 10;
}

.css-1nmdiq5-menu {
    z-index: 12 !important;
}

.merge_input_design .css-1nmdiq5-menu .css-10wo9uf-option,
.merge_input_design .css-1nmdiq5-menu .css-d7l1ni-option,
.merge_input_design .css-1nmdiq5-menu .css-10wo9uf-option {
    font-size: 13px;
    font-weight: 500;
    color: #0A0A0A;
    /* text-align: center; */
}

.merge_input_design .css-1nmdiq5-menu .css-1n6sfyn-MenuList {
    padding: 0;
}

.merge_input_design .css-1nmdiq5-menu .css-qr46ko .css-d7l1ni-option,
.merge_input_design .css-1nmdiq5-menu .css-1n6sfyn-MenuList .css-d7l1ni-option {
    background-color: #b7b7b721;
}

.merge_input_design .css-1nmdiq5-menu .css-tr4s17-option {
    font-size: 13px;
    font-weight: 500;
    /* text-align: center; */
    color: #4A3AFF;
    background-color: #4a3aff0f;
}

.modify_btn:active,
.modify_btn:focus,
.modify_btn:hover,
.modify_btn {
    background-color: transparent !important;
    padding: 0 0 0 3px;
    border: none;
    font-size: 12px;
    color: #0A0A0A !important;
    text-decoration: underline;
}

.inner_design_react_dropdown .css-t3ipsp-control,
.inner_design_react_dropdown .css-13cymwt-control {
    width: 100%;
    border-radius: 20px;
}

.input_addon_cls.accordion_react_select_design .css-t3ipsp-control,
.input_addon_cls.accordion_react_select_design .css-13cymwt-control {
    min-height: 36px !important;
    height: 36px !important;
}

.input_addon_cls .input_type_file_design {
    border-radius: 100px;
    border: 1px solid #CFD3D4;
    font-size: 13px;
    background-color: #FFF;
    width: 448px;
    height: 36px;
    color: #CFD3D4;
    font-weight: 400;
    padding: 0 40px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.input_addon_cls .input_type_file_design input[type="file"] {
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
    height: 100%;
    cursor: pointer;
}

.input_addon_cls .input_type_file_design input[type="file"]::after {
    content: 'Browse Files';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
    background-color: #E0E0E0;
    color: #424242;
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input_addon_cls .input_type_file_design input[type="file"]:hover::after {
    background-color: #CFD3D4;
}

.custom_under_line_design {
    text-decoration: none;
}

.custom_under_line_design:hover {
    text-decoration: underline;
}

.btn_active_user {
    padding: 8.5px 53px;
    box-shadow: 0px 0px 24.7px 8px rgba(74, 58, 255, 0.25);
    margin-top: 19px;
}

.btn_active_user:hover {
    box-shadow: none;
}

.css-16xfy0z-control {
    border-radius: 20px !important;
    border-color: transparent !important;
    height: 36px !important;
    min-height: 36px !important;
}