.Toastify__toast.Toastify__toast-theme--light.Toastify__toast--error.Toastify__toast--close-on-click {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 5px 7px !important;
    margin: 0;
    min-height: 50px !important;
    height: 50px !important;
}

.Toastify__toast-container {
    padding: 0 !important;
    max-width: 600px !important;
    width: 600px !important;
}

.Toastify__toast {
    margin: 0 !important;
    min-height: auto !important;
    height: auto !important;
    padding: 5px 8px !important;
}

.Toastify__toast-container--top-right {
    left: 50%;
    transform: translateX(-50%) !important;
}

.Toastify__toast-theme--light {
    border-radius: 16px !important;
    background: rgba(255, 255, 255, 0.90) !important;
}

.Toastify__progress-bar--animated {
    opacity: 0 !important;
}

.Toastify__toast-body {
    margin: 0 !important;
    padding: 0 !important;
}

.Toastify__close-button {
    align-self: auto !important;
    line-height: normal !important;
}

.Toastify__toast-body>div:last-child {
    color: #0A0A0A;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.07px;
    text-align: left;
}

.Toastify__close-button--light {
    opacity: 1 !important;
}

.Toastify__close-button.Toastify__close-button--light svg {
    width: 24px;
    height: 24px;
    transform: translateY(-1px);
}

.Toastify__close-button.Toastify__close-button--light svg path {
    color: #424242;
}

.Toastify--animate-icon {
    display: none !important;
}